<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <div class="card mb-3">
        <div class="card-body">
          <b-row align-v="center">
            <b-col lg="auto">
              <h5 class="card-title mb-0">Data Period</h5>
            </b-col>
            <b-col lg="3">
              <date-picker range value-type="format" prefix-class="crm" v-model="dateFilter" v-b-tooltip.hover="'Filter by Date'" format="YYYY-MM-DD" @input="doFilterDate" :disabled-date="notAfterToday" />
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="gutter-2">
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <b-row class="gutter-2 justify-content-center">
                <b-col lg="6">
                  <div class="card_dashboard bg_card_1">
                    <b-row class="gutter-2" align-v="center">
                      <b-col lg="auto">
                        <div class="wrap_ic_dsb">
                          <i class="ti-layout-list-thumb"></i>
                        </div>
                      </b-col>
                      <b-col lg>
                        <div class="wrap_total_sts">
                          <router-link :to="{name: 'BoProspect', query: {'start-date': dateFilter[0], 'end-date': dateFilter[1]}}">
                            <h3>{{ data.totalProspects }}</h3>
                            <p>Prospects</p>
                            <div 
                              class="info"
                              v-b-tooltip.hover
                              title="Total all Prospects including Prospects that have become Projects"
                            >
                              <i class="ti-info"></i>
                            </div>
                          </router-link>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>                
                <b-col lg="6">
                  <div class="card_dashboard bg_card_2">
                    <router-link :to="{name: 'BoCustomer', query: {'start-date': dateFilter[0], 'end-date': dateFilter[1]}}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.closing }}</h3>
                            <p>Closing</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_2">
                    <router-link :to="{name: 'BoProspect', query: {'start-date': dateFilter[0], 'end-date': dateFilter[1], 'status': 'FU'}}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.followUp }}</h3>
                            <p>Follow-up</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <router-link :to="{name: 'BoSurveySchedule', query: {'start-date': dateFilter[0], 'end-date': dateFilter[1]}}">
                    <div class="card_dashboard bg_card_3">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.survey }}</h3>
                            <p>Survey</p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </router-link>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_4">
                    <b-row class="gutter-2" align-v="center">
                      <b-col lg="auto">
                        <div class="wrap_ic_dsb">
                          <i class="ti-layout-list-thumb"></i>
                        </div>
                      </b-col>
                      <b-col lg>
                        <div class="wrap_total_sts">
                          <router-link :to="{name: 'BoCustomer', query: {status: 'R'}}">
                            <h3>{{ data.runningProjects }}</h3>
                            <p>Running Projects</p>
                            <div 
                              class="info"
                              v-b-tooltip.hover
                              title="Total running project from the closing prospect"
                            >
                              <i class="ti-info"></i>
                            </div>
                          </router-link>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>                
                <b-col lg="6">
                  <div class="card_dashboard bg_card_1">
                    <router-link :to="{name: 'BoAfterSalesService'}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.finishedProjects }}</h3>
                            <p>Finished Projects</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_3">
                    <router-link :to="{name: 'BoAfterSalesService', query: {cm: 'Y'}}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.customerComplaints }}</h3>
                            <p>Customer Complaints</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_2">
                    <router-link :to="{name: 'BoProspect', query: {status: 'R'}}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.rejectedProjects }}</h3>
                            <p>Rejected Projects</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_1">
                    <router-link :to="{name: 'BoCustomer', query: {status: 'S'}}">
                      <b-row class="gutter-2" align-v="center">
                        <b-col lg="auto">
                          <div class="wrap_ic_dsb">
                            <i class="ti-layout-list-thumb"></i>
                          </div>
                        </b-col>
                        <b-col lg>
                          <div class="wrap_total_sts">
                            <h3>{{ data.pendingProjects }}</h3>
                            <p>Pending Projects</p>
                          </div>
                        </b-col>
                      </b-row>
                    </router-link>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="card_dashboard bg_card_3">
                    <b-row class="gutter-2" align-v="center">
                      <b-col lg="auto">
                        <div class="wrap_ic_dsb">
                          <i class="ti-layout-list-thumb"></i>
                        </div>
                      </b-col>
                      <b-col lg>
                        <div class="wrap_total_sts">
                          <h3>{{ data.forcePayment }}</h3>
                          <p>Unfinished Project</p>

                          <div 
                            class="info"
                            v-b-tooltip.hover
                            title="Total Unfinished Projects / Completed Projects With Forced Payments"
                          >
                            <i class="ti-info"></i>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            
            <b-col md="6">
              <b-card class="mb-0">
                <template #header="">
                  <h5 class="card-title">Revenue Category</h5>
                </template>
                <v-chart :option="optionsRevenueCat" class="chart" />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <template #header="">
              <h5 class="card-title">Prospect Category</h5>
            </template>
            <v-chart :option="optionsProspectCat" class="chart" />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <template #header="">
              <h5 class="card-title">Customer Category</h5>
            </template>
            <v-chart :option="optionsCustCat" class="chart" />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <template #header="">
              <h5 class="card-title">Prospect Source</h5>
            </template>
            <v-chart :option="optionsSourceProspect" class="chart" />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <template #header="">
              <h5 class="card-title">Prospect Contact Media</h5>
            </template>
            <v-chart :option="optionsProspectContactMedia" class="chart" />
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card class="mb-0">
            <template #header="">
              <h5 class="card-title">Daily Prospect</h5>
            </template>
            <v-chart :option="dailyProspectChart" class="chart" />
          </b-card>
        </b-col>
      </b-row>    
    </div>
  </b-container>
</template>
<style lang="scss" scoped>
  .chart{
    height: 480px;
  }

  .card_dashboard {
    .wrap_total_sts {
      position: relative;

      .info {
        position: absolute;
        top: -1em;
        right: -.25em;

        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #000;
        color: #fff;
      }
    }
    
  }
</style>
<script>
import GlobalVue from '../../../libs/Global.vue'
// import Gen from '@/libs/Gen'
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { BarChart, PieChart, LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import DatePicker from 'vue2-datepicker'

use([
  SVGRenderer,
  BarChart,
  PieChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])
export default {
  extends: GlobalVue,
  components: {
    VChart,
    DatePicker,
  },
  data() {
    return {
      dailyProspect: {},
      dateFilter: [],
      optionsSourceProspect: {},
      optionsProspectContactMedia: {},
      optionsRevenueCat: {},
      optionsCustCat: {},
      optionsProspectCat: {}
    }
  },
  methods: {
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    notAfterToday(date){
      return date > new Date()
    },
  },
  computed: {
    dailyProspectChart(){
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: data => {            
            return `
            <div class="mb-2"><strong>${data[0].name} </strong></div>
              <div class="tooltip-content">
                <div class="d-flex mb-1">
                  <div class="mr-1">
                    ${data[0].marker}
                  </div>
                  <div>                    
                    ${data[0].data} Prospect${data[0].data > 1 ? 's' : ''}                    
                  </div>
                </div>
              </div>
            </div>
          `
          }
        },
        color: ['#0076BB', '#9CEC5B'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.dailyProspect.xAxis
          },
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: this.dailyProspect.series
      }
    }
  },
  mounted(){
    this.apiGet()

    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  watch: {
    $route(){
      this.apiGet()
    },
    'filter'(){
      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    }
  }

}
</script>